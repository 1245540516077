import { Checkbox } from '@/components/atoms/Checkbox/Checkbox';
import { ExpandAnimation } from '@/components/atoms/ExpandAnimation/ExpandAnimation';
import Accordion from '@/components/molecules/Accordion';
import { EVSavingsToggle } from '@/components/molecules/EVSavingsToggle/EVSavingsToggle';
import { useVehicleFilterSettingsContext } from '@/context/VehicleFilterSettings';
import { isEvaluatedIncentive } from '@/lib/incentiveUtils';
import { priceFormatter } from '@/lib/priceFormatter';
import {
  JDPowerIncentive,
  VehicleIncentivesData,
} from '@/lib/schema/incentives/types';
import clsx from 'clsx';
import { FC, useMemo } from 'react';
import { CalculatorFuelSavingsProps } from '../PaymentCalculator/types';

interface EVSavingsSummaryAccordionProps extends EVSavingsSummaryProps {
  variant?: 'default' | 'calculator';
}
interface SelectedJDPowerIncentive extends JDPowerIncentive {
  selected: boolean;
}
interface EVSavingsSummaryProps {
  incentivesData?: VehicleIncentivesData;
  selectedIncentives?: JDPowerIncentive[];
  incentivesTotal?: number;
  openIncentiveModal?: () => void;
  gasSavings?: CalculatorFuelSavingsProps;
}
const IncentiveLineItem = ({
  incentivesList,
  openIncentiveModal,
}: {
  incentivesList: SelectedJDPowerIncentive[];
  openIncentiveModal?: () => void;
}) => {
  return (
    <div className="flex flex-col gap-s">
      {incentivesList.map((incentive, i) => (
        <div
          key={`${incentive.id}-${i}`}
          aria-label={openIncentiveModal ? 'Open Incentive Modal' : ''}
          className={clsx('flex justify-between', {
            'cursor-pointer hover:text-blue-medium': openIncentiveModal,
          })}
          onClick={openIncentiveModal}
        >
          <div className="text-body2Light text-neutral-800">
            {incentive.name}
          </div>
          <div
            className={clsx('text-body2Light', {
              'text-neutral-600 line-through': !incentive.selected,
              'text-neutral-800': incentive.selected,
            })}
          >
            -
            {priceFormatter(
              isEvaluatedIncentive(incentive)
                ? incentive?.evaluation.amount_in_purchase
                : incentive?.max_amount || 0
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export const EVSavingsSummary: React.FC<EVSavingsSummaryProps> = ({
  incentivesData,
  selectedIncentives,
  gasSavings,
  incentivesTotal = 0,
  openIncentiveModal,
}) => {
  const {
    settings: { displayFuelSavings },
    handleSetDisplayFuelSavings,
  } = useVehicleFilterSettingsContext();
  const showIncentives = process.env.NEXT_PUBLIC_ENABLE_INCENTIVES === 'true';
  const calcTermYears = gasSavings ? Number(gasSavings.timePeriod) : 1;
  const gasSavingsPerYear =
    Number(gasSavings?.fuelSavingsEstimate) / calcTermYears || 0;

  const { rebates, incentives } = useMemo(() => {
    const incentivesFlat = [
      incentivesData?.state || [],
      incentivesData?.federal || [],
      incentivesData?.local || [],
    ].flat();

    return (
      incentivesFlat?.reduce(
        (
          acc: {
            rebates: SelectedJDPowerIncentive[];
            incentives: SelectedJDPowerIncentive[];
          },
          incentive: JDPowerIncentive
        ) => {
          if (incentive.max_amount === null) return acc;

          if (incentive.type === 'Rebate') {
            acc.rebates.push({
              ...incentive,
              selected: !!selectedIncentives?.some(
                (selectedIncentive) => selectedIncentive.id === incentive.id
              ),
            });
          } else {
            acc.incentives.push({
              ...incentive,
              selected: !!selectedIncentives?.some(
                (selectedIncentive) => selectedIncentive.id === incentive.id
              ),
            });
          }
          return acc;
        },
        { rebates: [], incentives: [] }
      ) || { rebates: [], incentives: [] }
    );
  }, [incentivesData, selectedIncentives]);

  const totalSavings =
    incentivesTotal +
    (displayFuelSavings ? gasSavingsPerYear * calcTermYears : 0);
  if (!showIncentives) return null;
  return (
    <div className="flex flex-col gap-s rounded-xsmall bg-blue-light p-s">
      {incentives.length > 0 && (
        <div className="flex flex-col">
          <IncentiveLineItem
            openIncentiveModal={openIncentiveModal}
            incentivesList={incentives}
          />
        </div>
      )}
      {incentives.length > 0 && rebates.length > 0 && (
        <div className="flex w-full border-b border-neutral-600" />
      )}

      {rebates.length > 0 && (
        <div className="flex flex-col">
          <IncentiveLineItem
            openIncentiveModal={openIncentiveModal}
            incentivesList={rebates}
          />
        </div>
      )}
      {(incentives.length > 0 || rebates.length > 0) && (
        <div className="flex w-full border-b border-neutral-600" />
      )}
      <div className="flex gap-s">
        <Checkbox
          aria-label={`${displayFuelSavings ? 'Hide' : 'Show'} gas saving`}
          checked={displayFuelSavings}
          onChange={handleSetDisplayFuelSavings}
        />
        <div className="flex w-full justify-between">
          <div
            className="cursor-pointer text-body2Light text-neutral-800"
            onClick={() => handleSetDisplayFuelSavings(!displayFuelSavings)}
          >
            Gas savings over {calcTermYears} year{calcTermYears > 1 ? 's' : ''}{' '}
          </div>
          <div
            className={clsx('text-body2Light text-neutral-800', {
              '!text-neutral-600 line-through': !displayFuelSavings,
            })}
          >
            -{priceFormatter(gasSavingsPerYear * calcTermYears)}
          </div>
        </div>
      </div>
      <div className="flex w-full border border-t-blue-dark" />
      <div className="flex justify-between text-body2Medium text-blue-dark">
        <div className="">Total Savings</div>
        <div>-${Math.round(totalSavings).toLocaleString()}</div>
      </div>
    </div>
  );
};

export const EVSavingsSummaryAccordion: FC<EVSavingsSummaryAccordionProps> = ({
  incentivesData,
  selectedIncentives,
  gasSavings,
  incentivesTotal = 0,
  openIncentiveModal,
  variant = 'default',
}) => {
  const {
    settings: { evSavings },
  } = useVehicleFilterSettingsContext();
  return (
    <Accordion
      title={
        <div className="flex w-full flex-col gap-s">
          <div className="flex w-full justify-between">
            <div
              className={clsx('text-h4Regular m:text-body1Medium', {
                '!text-body1Light': variant === 'calculator',
              })}
            >
              EV Savings
            </div>
            <EVSavingsToggle />
          </div>

          <ExpandAnimation expanded={!evSavings} duration={0}>
            <div
              className="text-body2Light text-neutral-800 transition-opacity duration-250"
              style={{
                opacity: evSavings ? 0 : 1,
              }}
            >
              Switch on to include incentives, rebates, and estimated gas
              savings.
            </div>
          </ExpandAnimation>
        </div>
      }
      icon={null}
      expanded={evSavings}
      expandOnClick={false}
      animationDelay={{
        onExpand: 0,
        onCollapse: 250,
      }}
    >
      <div
        className={clsx(
          'flex flex-col gap-l pb-l transition-opacity duration-250',
          {
            'opacity-40': !evSavings,
          }
        )}
      >
        <EVSavingsSummary
          incentivesData={incentivesData}
          selectedIncentives={selectedIncentives}
          incentivesTotal={incentivesTotal}
          openIncentiveModal={openIncentiveModal}
          gasSavings={gasSavings}
        />
      </div>
    </Accordion>
  );
};
