import { CheckIcon } from '@heroicons/react/24/outline';
import React from 'react';

export interface CheckboxProps {
  disabled?: boolean;
  checked?: boolean;
  onChange: (val: boolean) => void;
  name?: string;
  checkBoxVariant?: CheckboxVariant;
  'aria-label': string;
}

enum CheckboxVariant {
  'Default' = 'bg-brandMidnight',
  'Turquoise' = 'bg-turquoise',
}

export const Checkbox: React.FC<CheckboxProps> = ({
  checked = false,
  onChange = () => {},
  checkBoxVariant = CheckboxVariant.Default,
  'aria-label': ariaLabel,
  ...props
}) => {
  return (
    <section
      aria-label={ariaLabel}
      className="flex cursor-pointer items-center"
      onClick={() => onChange(!checked)}
    >
      <input
        className="hidden"
        type="checkbox"
        checked={checked}
        onChange={(evt) => onChange(evt.currentTarget.checked)}
        {...props}
      />
      <div
        className={`flex h-5 w-5 items-center justify-center rounded-xsmall border-2 ${
          checked
            ? `border-transparent ${checkBoxVariant}`
            : 'border-gray-300 bg-white'
        }`}
      >
        <CheckIcon stroke="white" width={26} height={26} />
      </div>
    </section>
  );
};
